import { defineStore } from 'pinia';
import axios from 'axios';
import { toRaw } from 'vue';
import { useDashboardStore } from './dashboardStore';

export const useGraphPerformanceStore = defineStore('graphPerformance', {
	state: () => ({
		dataSet: {
			selected_dates: JSON.parse(localStorage.getItem('dashboardSelectedDates')),
			dashboardStore: useDashboardStore(),
			yearPrevious: 0,
			yearCurrent: 0,
			ConversionPrevious: [],
			ConversionCurrent: [],
			SessionsPrevious: [],
			SessionsCurrent: [],
			RevenuePrevious: [],
			RevenueCurrent: [],
			ImpressionsPrevious: [],
			ImpressionsCurrent: [],
			ConversionsPrevious: [],
			ConversionsCurrent: [],
		},
	}),
	getters: {
		additionalOptionsClicks() {
			return {
				colors: ['#bd9a5a', '#cfc3ad'],
				yaxis: [
					{
						axisTicks: {
							show: true,
						},
						axisBorder: {
							show: true,
							color: '#bd9a5a',
						},
						labels: {
							style: {
								color: '#fff',
							},
						},
						title: {
							text: '',
						},
					},
				],
			};
		},
		additionalOptionsSessions() {
			return {
				colors: ['#20016c', '#C8B9EC'],
				yaxis: [
					{
						axisTicks: {
							show: true,
						},
						axisBorder: {
							show: true,
							color: '#20016c',
						},
						labels: {
							style: {
								color: '#fff',
							},
						},
						title: {
							text: '',
						},
					},
				],
			};
		},
		additionalOptionsRevenue() {
			return {
				colors: ['#77d0ba', '#B4DAD1'],
				yaxis: [
					{
						axisTicks: {
							show: true,
						},
						axisBorder: {
							show: true,
							color: '#77d0ba',
						},
						labels: {
							style: {
								color: '#fff',
							},
						},
						title: {
							text: '',
						},
					},
				],
			};
		},
		additionalOptionsImpressions() {
			return {
				colors: ['#fa5c7c', '#FDC8D2'],
				yaxis: [
					{
						axisTicks: {
							show: true,
						},
						axisBorder: {
							show: true,
							color: '#fa5c7c',
						},
						labels: {
							style: {
								color: '#fff',
							},
						},
						title: {
							text: '',
						},
					},
				],
			};
		},
		additionalOptionsConversions() {
			return {
				colors: ['#f3d548', '#f7ecba'],
				yaxis: [
					{
						axisTicks: {
							show: true,
						},
						axisBorder: {
							show: true,
							color: '#f3d548',
						},
						labels: {
							style: {
								color: '#fff',
							},
						},
						title: {
							text: '',
						},
					},
				],
			};
		},
		chartSessionsSeries(state) {
			return [
				{
					type: 'column',
					name: 'Sessions ' + state.yearCurrent,
					data: toRaw(state.SessionsCurrent),
				},
				{
					type: 'column',
					name: 'Sessions ' + state.yearPrevious,
					data: toRaw(state.SessionsPrevious),
				},
			];
		},
		chartRevenueSeries(state) {
			return [
				{
					type: 'column',
					name: 'Revenue ' + state.yearCurrent,
					data: toRaw(state.RevenueCurrent),
				},
				{
					type: 'column',
					name: 'Revenue ' + state.yearPrevious,
					data: toRaw(state.RevenuePrevious),
				},
			];
		},
		chartImpressionsSeries(state) {
			return [
				{
					type: 'column',
					name: 'Impressions ' + state.yearCurrent,
					data: toRaw(state.ImpressionsCurrent),
				},
				{
					type: 'column',
					name: 'Impressions ' + state.yearPrevious,
					data: toRaw(state.ImpressionsPrevious),
				},
			];
		},
		chartConversionsSeries(state) {
			return [
				{
					type: 'column',
					name: 'Conversions ' + state.yearCurrent,
					data: toRaw(state.ConversionsCurrent),
				},
				{
					type: 'column',
					name: 'Conversions ' + state.yearPrevious,
					data: toRaw(state.ConversionsPrevious),
				},
			];
		},
	},
	actions: {
		async fetchDataClicks(selectedMarket, selectedProject, year) {
			const options = {
				market_id: selectedMarket,
				project_id: selectedProject,
				date_range_start: this.dataSet.selected_dates.from,
				date_range_end: this.dataSet.selected_dates.to,
				filter: this.dataSet.dashboardStore.getFilter,
				year: year
			};

			const response = await axios.post('vuejs/performance_chart_clicks', options, { withCredentials: true });

			this.ClicksPrevious = response.data.clicks_previous;
			this.ClicksCurrent = response.data.clicks_current;
			this.yearPrevious = response.data.previous_year;
			this.yearCurrent = response.data.current_year;

			return [
				{
					type: 'column',
					name: 'Clicks ' + this.yearCurrent,
					data: toRaw(this.ClicksCurrent),
				},
				{
					type: 'column',
					name: 'Clicks ' + this.yearPrevious,
					data: toRaw(this.ClicksPrevious),
				},
			];
		},
		async fetchDataSessions(selectedMarket, selectedProject, year) {
			const options = {
				market_id: selectedMarket,
				project_id: selectedProject,
				date_range_start: this.dataSet.selected_dates.from,
				date_range_end: this.dataSet.selected_dates.to,
				filter: this.dataSet.dashboardStore.getFilter,
				year: year
			};

			const response = await axios.post('vuejs/performance_chart_sessions', options, { withCredentials: true });

			this.SessionsPrevious = response.data.sessions_previous;
			this.SessionsCurrent = response.data.sessions_current;
			this.yearPrevious = response.data.previous_year;
			this.yearCurrent = response.data.current_year;

			return [
				{
					type: 'column',
					name: 'Sessions ' + this.yearCurrent,
					data: toRaw(this.SessionsCurrent),
				},
				{
					type: 'column',
					name: 'Sessions ' + this.yearPrevious,
					data: toRaw(this.SessionsPrevious),
				},
			];
		},
		async fetchDataRevenue(selectedMarket, selectedProject, year) {
			const options = {
				market_id: selectedMarket,
				project_id: selectedProject,
				date_range_start: this.dataSet.selected_dates.from,
				date_range_end: this.dataSet.selected_dates.to,
				filter: this.dataSet.dashboardStore.getFilter,
				year: year
			};

			const response = await axios.post('vuejs/performance_chart_revenue', options, { withCredentials: true });

			this.RevenuePrevious = response.data.revenue_previous;
			this.RevenueCurrent = response.data.revenue_current;
			this.yearPrevious = response.data.previous_year;
			this.yearCurrent = response.data.current_year;

			return [
				{
					type: 'column',
					name: 'Revenue ' + this.yearCurrent,
					data: toRaw(this.RevenueCurrent),
				},
				{
					type: 'column',
					name: 'Revenue ' + this.yearPrevious,
					data: toRaw(this.RevenuePrevious),
				},
			];
		},
		async fetchDataImpressions(selectedMarket, selectedProject, year) {
			const options = {
				market_id: selectedMarket,
				project_id: selectedProject,
				date_range_start: this.dataSet.selected_dates.from,
				date_range_end: this.dataSet.selected_dates.to,
				filter: this.dataSet.dashboardStore.getFilter,
				year: year
			};

			const response = await axios.post('vuejs/performance_chart_impressions', options, { withCredentials: true });

			this.ImpressionsPrevious = response.data.impressions_previous;
			this.ImpressionsCurrent = response.data.impressions_current;
			this.yearPrevious = response.data.previous_year;
			this.yearCurrent = response.data.current_year;

			return [
				{
					type: 'column',
					name: 'Impressions ' + this.yearCurrent,
					data: toRaw(this.ImpressionsCurrent),
				},
				{
					type: 'column',
					name: 'Impressions ' + this.yearPrevious,
					data: toRaw(this.ImpressionsPrevious),
				},
			];
		},
		async fetchDataConversions(selectedMarket, selectedProject, year) {
			const options = {
				market_id: selectedMarket,
				project_id: selectedProject,
				date_range_start: this.dataSet.selected_dates.from,
				date_range_end: this.dataSet.selected_dates.to,
				filter: this.dataSet.dashboardStore.getFilter,
				year: year
			};

			const response = await axios.post('vuejs/performance_chart_conversions', options, { withCredentials: true });

			this.ConversionsPrevious = response.data.conversions_previous;
			this.ConversionsCurrent = response.data.conversions_current;
			this.yearPrevious = response.data.previous_year;
			this.yearCurrent = response.data.current_year;

			return [
				{
					type: 'column',
					name: 'Conversions ' + this.yearCurrent,
					data: toRaw(this.ConversionsCurrent),
				},
				{
					type: 'column',
					name: 'Conversions ' + this.yearPrevious,
					data: toRaw(this.ConversionsPrevious),
				},
			];
		},
	},
	persist: false,
});

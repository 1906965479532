import { defineStore } from 'pinia';
import axios from 'axios';
import { toRaw } from 'vue';
import { useDashboardStore } from './dashboardStore';

export const useGraphEvolutionStore = defineStore('graphEvolution', {
	state: () => ({
		dataSet: {
			Conversion: {
				data: [],
			},
			Revenue: {
				data: [],
			},
			Transaction: {
				data: [],
			},
			Sessions: {
				data: [],
			},
			Impressions: {
				data: [],
			},
			Clicks: {
				data: [],
			},
		},
		ClicksOrSessions: 'Clicks',
	}),
	getters: {
		additionalOptions(state) {
			if (state.dataSet.Conversion.data.length) {
				return {
					colors: [(state.ClicksOrSessions === 'Clicks' ? '#bd9a5a' : '#20016c'), '#77d0ba', '#fa5c7c'],
					yaxis: [
						{
							axisTicks: {
								show: true,
							},
							axisBorder: {
								show: true,
								color: (state.ClicksOrSessions === 'Clicks' ? '#bd9a5a' : '#20016c'),
							},
							labels: {
								style: {
									color: '#fff',
								},
							},
							title: {
								text: state.ClicksOrSessions,
							},
						},
						{
							axisTicks: {
								show: true,
							},
							axisBorder: {
								show: true,
								color: '#77d0ba',
							},
							labels: {
								style: {
									color: '#fff',
								},
							},
							title: {
								text: 'Conversion',
							},
						},
						{
							opposite: true,
							axisTicks: {
								show: true,
							},
							axisBorder: {
								show: true,
								color: '#fa5c7c',
							},
							labels: {
								style: {
									color: '#fff',
								},
							},
							title: {
								text: 'Impressions',
							},
						},
					],
				};
			} else if (state.dataSet.Revenue.data.length) {
				return {
					colors: [(state.ClicksOrSessions === 'Clicks' ? '#bd9a5a' : '#20016c'), '#77d0ba', '#fa5c7c'],
					yaxis: [
						{
							axisTicks: {
								show: true,
							},
							axisBorder: {
								show: true,
								color: (state.ClicksOrSessions === 'Clicks' ? '#bd9a5a' : '#20016c'),
							},
							labels: {
								style: {
									color: '#fff',
								},
							},
							title: {
								text: state.ClicksOrSessions,
							},
						},
						{
							opposite: true,
							axisTicks: {
								show: true,
							},
							axisBorder: {
								show: true,
								color: '#77d0ba',
							},
							labels: {
								style: {
									color: '#fff',
								},
							},
							title: {
								text: 'Revenue',
							},
						},
						{
							opposite: true,
							axisTicks: {
								show: true,
							},
							axisBorder: {
								show: true,
								color: '#fa5c7c',
							},
							labels: {
								style: {
									color: '#000',
								},
							},
							title: {
								text: 'Impressions',
							},
						},
					],
				};
			} else if (state.dataSet.Transaction.data.length) {
				return {
					colors: [(state.ClicksOrSessions === 'Clicks' ? '#bd9a5a' : '#20016c'), '#77d0ba', '#fa5c7c'],
					yaxis: [
						{
							axisTicks: {
								show: true,
							},
							axisBorder: {
								show: true,
								color: (state.ClicksOrSessions === 'Clicks' ? '#bd9a5a' : '#20016c'),
							},
							labels: {
								style: {
									color: '#fff',
								},
							},
							title: {
								text: state.ClicksOrSessions,
							},
						},
						{
							opposite: true,
							axisTicks: {
								show: true,
							},
							axisBorder: {
								show: true,
								color: '#77d0ba',
							},
							labels: {
								style: {
									color: '#fff',
								},
							},
							title: {
								text: 'Transactions',
							},
						},
						{
							opposite: true,
							axisTicks: {
								show: true,
							},
							axisBorder: {
								show: true,
								color: '#fa5c7c',
							},
							labels: {
								style: {
									color: '#fff',
								},
							},
							title: {
								text: 'Impressions',
							},
						},
					],
				};
			} else if (state.dataSet.Clicks.data.length) {
				return {
					colors: [(state.ClicksOrSessions === 'Clicks' ? '#bd9a5a' : '#20016c'), '#fa5c7c'],
					yaxis: [
						{
							axisTicks: {
								show: true,
							},
							axisBorder: {
								show: true,
								color: (state.ClicksOrSessions === 'Clicks' ? '#bd9a5a' : '#20016c'),
							},
							labels: {
								style: {
									color: '#fff',
								},
							},
							title: {
								text: state.ClicksOrSessions,
							},
						},
						{
							opposite: true,
							axisTicks: {
								show: true,
							},
							axisBorder: {
								show: true,
								color: '#fa5c7c',
							},
							labels: {
								style: {
									color: '#fff',
								},
							},
							title: {
								text: 'Impressions',
							},
						},
					],
				};
			}
		},
		switchClicksSessions(state) {
			if (state.ClicksOrSessions === 'Sessions') {
				return {
					type: 'column',
					name: 'Sessions',
					data: toRaw(state.dataSet.Sessions.data),
				}
			} else if (state.ClicksOrSessions === 'Clicks') {
				return {
					type: 'column',
					name: 'Clicks',
					data: toRaw(state.dataSet.Clicks.data),
				}
			}
		},
		chartSeries(state) {
			if (state.dataSet.Conversion.data.length) {
				return [
					this.switchClicksSessions,
					{
						type: 'column',
						name: 'Conversion',
						data: toRaw(state.dataSet.Conversion.data),
					},
					{
						type: 'line',
						name: 'Impressions',
						data: toRaw(state.dataSet.Impressions.data),
					},
				];
			} else if (state.dataSet.Revenue.data.length) {
				return [
					this.switchClicksSessions,
					{
						type: 'column',
						name: 'Revenue',
						data: toRaw(state.dataSet.Revenue.data),
					},
					{
						type: 'line',
						name: 'Impressions',
						data: toRaw(state.dataSet.Impressions.data),
					},
				];
			} else if (state.dataSet.Transaction.data.length) {
				return [
					this.switchClicksSessions,
					{
						type: 'column',
						name: 'Transactions',
						data: toRaw(state.dataSet.Transaction.data),
					},
					{
						type: 'line',
						name: 'Impressions',
						data: toRaw(state.dataSet.Impressions.data),
					},
				];
			} else if (state.dataSet.Sessions.data.length) {
				return [
					this.switchClicksSessions,
					{
						type: 'line',
						name: 'Impressions',
						data: toRaw(state.dataSet.Impressions.data),
					},
				];
			} else if (state.dataSet.Clicks.data.length) {
				return [
					this.switchClicksSessions,
					{
						type: 'line',
						name: 'Impressions',
						data: toRaw(state.dataSet.Impressions.data),
					},
				];
			} else {
				return [];
			}
		},
	},
	actions: {
		async fetchData(selectedMarket, selectedProject) {
			const selected_dates = JSON.parse(localStorage.getItem('dashboardSelectedDates'));
			const dashboardStore = useDashboardStore();

			const options = {
				market_id: selectedMarket,
				project_id: selectedProject,
				date_range_start: selected_dates.from,
				date_range_end: selected_dates.to,
				filter: dashboardStore.getFilter,
			};

			const response = await axios.post('vuejs/evolution_chart', options, { withCredentials: true });

			this.dataSet = response.data.data;

			return response.data.data;
		},
	},
	persist: false,
});
